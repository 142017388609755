import React, { FC, ReactElement, ReactNode } from "react";
import Colors from "../colors";
import { Typography } from "../Typography";
import Icon from "../Icon";
import { FlexCol, FlexRow } from "../Layout/Flex";
import Gutter from "../Gutter";
import styles from "./Button.module.css";
import classNames from "classnames";
import LassieLoader from "../LassieLoader";
import { TypographyVariant } from "../Typography/types";

export enum ButtonVariants {
  default = "default",
  danger = "danger",
  outlined = "outlined",
  custom = "custom",
}

enum BorderRadiusVariants {
  defaultRadius = "defaultRadius",
  squareRounded = "squareRounded",
}

interface ButtonPropType {
  variant?: keyof typeof ButtonVariants;
  title?: string | ReactElement;
  onPress: () => void;
  disabled?: boolean;
  loading?: boolean;
  color?: keyof typeof Colors;
  textColor?: keyof typeof Colors;
  icon?: string | ReactNode;
  iconFirst?: boolean;
  textVariant?: keyof typeof TypographyVariant;
  borderRadiusVariant?: keyof typeof BorderRadiusVariants;
  big?: boolean;
  small?: boolean;
  testId?: string;
  textStyle?: any;
  style?: any;
  className?: string;
  tabIndex?: number;
  autoFocus?: boolean;
}
const Button: FC<ButtonPropType> = ({
  variant,
  title,
  onPress,
  disabled,
  loading,
  color,
  textColor,
  icon,
  iconFirst,
  textVariant,
  borderRadiusVariant,
  big,
  small,
  testId,
  textStyle,
  style,
  className,
  tabIndex,
  autoFocus,
}) => {
  const buttonTextColor =
    disabled && loading
      ? "white"
      : disabled || loading
      ? "grey300"
      : textColor || "white";

  const iconComponent = !!icon && (
    <div className={styles.iconContainer}>
      {typeof icon === "string" ? (
        <Icon name={icon} size={20} color={Colors[buttonTextColor]} />
      ) : (
        icon
      )}
    </div>
  );
  return (
    <button
      autoFocus={autoFocus}
      data-test-id={testId}
      disabled={disabled || loading}
      onClick={() => !loading && onPress()}
      tabIndex={tabIndex}
      className={classNames(
        styles.button,
        styles.sizeBig,
        variant === ButtonVariants.default && styles.variantDefault,
        variant === ButtonVariants.danger && styles.variantDanger,
        borderRadiusVariant === BorderRadiusVariants.defaultRadius &&
          styles.radiusBig,
        borderRadiusVariant === BorderRadiusVariants.squareRounded &&
          styles.radiusSquareRounded,
        big && styles.big,
        small && styles.small,
        variant === ButtonVariants.outlined && styles.variantOutlined,
        (disabled || loading) && styles.disabled,
        className
      )}
      style={{
        backgroundColor:
          variant === ButtonVariants.custom && color && !disabled
            ? Colors[color]
            : undefined,
        borderColor:
          variant === ButtonVariants.outlined && !disabled && textColor
            ? Colors[textColor]
            : undefined,
        ...style,
      }}
    >
      <FlexRow
        justifyContent="center"
        style={title ? { paddingLeft: 16, paddingRight: 16 } : {}}
      >
        <>
          <div
            className={styles.loadingContainer}
            style={{
              opacity: loading ? 1 : 0,
              transition: "all 200ms ease-in-out",
            }}
          >
            <FlexRow justifyContent={"center"} alignItems="center">
              <LassieLoader
                size={48}
                white={color !== "white" && variant !== ButtonVariants.outlined}
              />
            </FlexRow>
          </div>

          {iconFirst && !loading && (
            <>
              <FlexCol justifyContent="center" style={{ width: "fit-content" }}>
                {iconComponent}
              </FlexCol>
              <Gutter horizontal size={1} />
            </>
          )}
          <div
            style={{
              opacity: loading ? 0 : 1,
              transition: "all 200ms ease-in-out",
            }}
          >
            <Typography
              variant={textVariant}
              color={buttonTextColor}
              style={textStyle}
            >
              <strong>{title}</strong>
            </Typography>
          </div>
          {icon && !iconFirst && !loading && (
            <>
              <Gutter horizontal size={1} />
              <FlexCol justifyContent="center" style={{ width: "fit-content" }}>
                {iconComponent}
              </FlexCol>
            </>
          )}
        </>
      </FlexRow>
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  variant: ButtonVariants.default,
  color: undefined,
  textColor: undefined,
  icon: undefined,
  iconFirst: false,
  borderRadiusVariant: BorderRadiusVariants.defaultRadius,
  textStyle: {},
  tabIndex: 0,
  autoFocus: false,
};

export default Button;

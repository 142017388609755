import { GrowthBook } from "@growthbook/growthbook-react";
import { Experiment, Result } from "@growthbook/growthbook";
import { captureEvent } from "@sentry/nextjs";
import { inProduction } from "../config";
import crossFetch from "cross-fetch";
import { setPolyfills } from "@growthbook/growthbook";
import { deviceId } from "components/Signup/state";

export function getServerSideGrowthBookContext() {
  setPolyfills({
    fetch: crossFetch,
  });

  return {
    apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    decryptionKey: process.env.NEXT_PUBLIC_GROWTHBOOK_DECRYPTION_KEY,
  };
}

const onExperimentViewed = (
  experiment: Experiment<any>,
  result: Result<any>
) => {
  const experimentId = experiment.key;
  const variationId = result.key;

  const amplitude = global.window.amplitude;
  //  Only track to amplitude if production?
  if (amplitude) {
    amplitude.track("Experiment Viewed", {
      experimentId: experiment.key,
      variantId: result.key,
    });
  } else {
    const message = `Could not use experiment ${experiment.key}, due to amplitude not present`;
    inProduction
      ? captureEvent({
          message,
        })
      : console.log(message);
  }

  console.log("Growthbook: onExperimentViewed callback", {
    experimentId,
    variationId,
  });
};

// Let the GrowthBook instance know when the URL changes so the active
// experiments can update accordingly
export const updateGrowthBookURL = () => {
  if (!gbClient) {
    console.log(
      `GrowthBook client not initialized, skipping updateGrowthBookURL`
    );
    return;
  }
  return gbClient.setURL(window.location.href);
};

let gbClient: InstanceType<typeof GrowthBook> | null = null;

export const getClient = () => {
  if (
    !process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST ||
    !process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY
  ) {
    console.log(`GrowthBook client not initialized (missing env vars)`);
    return;
  }

  if (!gbClient) {
    // Create a client-side GrowthBook instance
    gbClient = new GrowthBook({
      apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
      clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
      // Enable easier debugging of feature flags during development
      enableDevMode: process.env.NODE_ENV === "development",
      subscribeToChanges: true,
      trackingCallback: onExperimentViewed,
      attributes: {
        id: deviceId(),
      },
    });

    gbClient.loadFeatures();
    console.log(`GrowthBook client initialized`);
  }

  return gbClient;
};

import { FunctionComponent, useEffect, useRef, useState } from "react";
import type { ReactElement, ReactNode } from "react";
import {
  Accordion as ReachAccordion,
  AccordionItem as ReachAccordionItem,
  AccordionButton as ReachAccordionButton,
  AccordionPanel as ReachAccordionPanel,
  useAccordionItemContext,
} from "@reach/accordion";

import { IconFrame } from "@/ui-kit";
import type { Glyph } from "@/ui-kit";

import { Typography } from "ui-kit/Typography";

import styles from "./Accordion.module.css";
import classNames from "classnames";

function useElementHeight() {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      requestAnimationFrame(() => {
        if (!entry) {
          return;
        }
        setHeight(entry.target.getBoundingClientRect().height);
      });
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { ref, height };
}

export const AccordionPanel = ({
  children,
  hasIcon = false,
}: {
  children: ReactElement | string;
  hasIcon?: boolean;
}) => {
  const { isExpanded } = useAccordionItemContext();
  const { ref, height } = useElementHeight();

  return (
    <ReachAccordionPanel
      className={classNames(styles.accordionPanel, {
        [styles.hasIcon]: hasIcon,
      })}
      hidden={false}
      aria-hidden={!isExpanded}
      style={{ maxHeight: isExpanded ? height : 0 }}
    >
      <div
        ref={ref}
        className={classNames(
          styles.contentWrapper,
          typeof children === "string" && styles.contentWrapperString
        )}
      >
        {typeof children === "string" ? (
          <div className={styles.textContainer}>{children}</div>
        ) : (
          children
        )}
      </div>
    </ReachAccordionPanel>
  );
};

export const AccordionTitle = ({
  children,
  icon,
}: {
  children: string | ReactElement | ReactElement[];
  icon?: Glyph;
}) => {
  const { isExpanded } = useAccordionItemContext();

  return (
    <ReachAccordionButton
      className={classNames(styles.accordionTitle, {
        [styles.isExpanded]: isExpanded,
      })}
    >
      {icon ? <IconFrame icon={icon} className={styles.icon} /> : null}
      {typeof children === "string" ? (
        <Typography className={styles.titleText} variant="body2">
          <strong>{children}</strong>
        </Typography>
      ) : (
        <>{children}</>
      )}

      <IconFrame
        icon={isExpanded ? "chevron-up" : "chevron-down"}
        iconSize="S"
        className={styles.iconChevron}
      />
    </ReachAccordionButton>
  );
};

export const AccordionItem = ({
  children,
  isHidden,
}: {
  children: ReactNode[];
  isHidden?: boolean;
}) => {
  return (
    <ReachAccordionItem
      className={classNames(styles.accordionItem, {
        [styles.isHidden]: isHidden,
      })}
    >
      {children}
    </ReachAccordionItem>
  );
};

export const Accordion = ({
  children,
  multiple,
  collapsible,
  index,
  className,
}: {
  children: ReactNode | ReactNode[];
  multiple?: boolean;
  collapsible?: boolean;
  index?: number | number[];
  className?: string;
}) => {
  return (
    <ReachAccordion
      multiple={multiple}
      collapsible={collapsible}
      index={index}
      className={classNames(styles.accordion, className)}
    >
      {children}
    </ReachAccordion>
  );
};

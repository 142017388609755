import set from "lodash.set";

/**
 * Transforms a nested object into a query string
 * @param obj Any object
 * @param parent In case of a nested object, this is the parent key
 * @returns A valid query string with nested objects in dot notation
 */
export const objectToQueryString = (
  obj: Record<string, any>,
  parent?: string
): string => {
  const str = [];

  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      const key = parent ? parent + `.${prop}` : prop;
      const value = obj[prop];

      str.push(
        value !== null && typeof value === "object"
          ? objectToQueryString(value, key)
          : encodeURIComponent(key) + "=" + encodeURIComponent(value)
      );
    }
  }
  return str.join("&");
};

function parseValue(value: any) {
  if (!value) {
    return value;
  }

  if (isFinite(value)) {
    return parseFloat(value);
  }

  if (value === "true") {
    return true;
  }
  if (value === "false") {
    return false;
  }

  return value;
}

/**
 * Parses a query string into a (nested) object
 * @param obj A NextJS router query object
 * @param preserveKeys A list of strings for keys that should not be parsed into `real` values, e.g. "true" -> true
 * @returns An object
 */
export function parseRouterQueryToObject(
  obj: Record<string, any>,
  preserveKeys: string[] = []
) {
  return Object.entries(obj).reduce((newObj, [key, value]) => {
    if (key === "slug") return newObj;

    const parsedValue = preserveKeys.includes(key) ? value : parseValue(value);

    return set(newObj, key, parsedValue);
  }, {} as any);
}

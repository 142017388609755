import { i18n, useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import NextI18nextConfig from "../../next-i18next.config";
import { formatCurrency, genitiveCase, joinArray } from "../utils/utils";
import { animalPronoun, speciesName } from "../utils/insurance";
import { getOrg } from "../utils/site";

export const useAppTranslation = (): {
  t: (key: string, options?: Record<string, string | number>) => string;
  i18n: typeof i18n;
  ready: boolean;
} => {
  const { t, i18n: i18nT, ready } = useTranslation("app");
  return {
    t: (key, options) => t(key, { ...options, context: getOrg() }),
    i18n: i18nT,
    ready,
  };
};

export const useWebTranslation = (): {
  t: (key: string, options?: Record<string, string | number>) => string;
  i18n: typeof i18n;
  ready: boolean;
} => {
  const { t, i18n: i18nT, ready } = useTranslation("web");

  const formatter = i18nT?.services?.formatter;
  formatter?.add("uppercase", (value, lng, options) => {
    return value.toUpperCase();
  });
  formatter?.add("lowercase", (value, lng, options) => {
    return value.toLowerCase();
  });
  formatter?.add("genitive_case", (value, lng, options) => {
    return genitiveCase(value, lng);
  });
  formatter?.add("currency", (value, code, options) => {
    return formatCurrency(value, code);
  });
  formatter?.add("species_name", (value, lng, options) => {
    return speciesName(value);
  });
  formatter?.add("join_array", (value, lng, options) => {
    return joinArray(value);
  });
  formatter?.add("animal_pronoun", (value, lng, options) => {
    return animalPronoun(options.sex, value);
  });

  return {
    t: (key, options) => t(key, { ...options, context: getOrg() }),
    i18n: i18nT,
    ready,
  };
};

export const useTranslationsReady = () => {
  const [ready, setReady] = useState(false);
  const { ready: appReady } = useAppTranslation();
  const { ready: webReady } = useWebTranslation();
  useEffect(() => {
    setReady(appReady && webReady);
  }, [appReady && webReady]);
  return ready;
};

export const namespaces = NextI18nextConfig.ns;

export const getWebTranslation = (key: string) => {
  return i18n?.t(key, { ns: "web", context: getOrg() }) || "";
};

export const getAppTranslation = (key: string) => {
  return i18n?.t(key, { ns: "app", context: getOrg() }) || "";
};

export { LinkT, EmailT, transHOC } from "./components";

export { i18n };

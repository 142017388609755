import { assertNever } from "utils/typescript";
import { glyphs } from "./icons";

type IconSize = "S" | "M";

export type Glyph = keyof typeof glyphs;

export type IconProps = {
  name: Glyph;
  size?: IconSize;
  color?: string;
  className?: string;
};

const getSize = (size: IconSize) => {
  switch (size) {
    case "S":
      return 16;
    case "M":
      return 20;
    default:
      // This should never happen
      assertNever(size);
  }
};

export const Icon = ({
  name,
  size = "S",
  color = "currentColor",
  className,
}: IconProps) => {
  const Component = glyphs[name];

  const sizeInPx = getSize(size);

  return (
    <Component
      width={sizeInPx}
      height={sizeInPx}
      color={color}
      className={className}
    />
  );
};

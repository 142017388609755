import {
  addDays,
  clamp,
  differenceInDays,
  differenceInYears,
  format,
  isValid,
  parseISO,
} from "date-fns";
import { i18n } from "next-i18next";
import { SpeciesEnum } from "../../__generated__/globalTypes";
import { NullableString } from "../types";
import { getMinimumAgeInDays, getStartOffsetInDays } from "./site";
import { formatCurrency } from "./utils";
import { dateValid } from "./validation";

export const MONTHLY_BILLING_INTERVAL = 1;
export const YEARLY_BILLING_INTERVAL = 12;

export const planName = (
  limit: number | undefined,
  species: SpeciesEnum | null
): string => {
  //TODO: Handle limit for different orgs better
  if (species === SpeciesEnum.dog) {
    if (limit === 0) {
      return i18n?.t("plan_name.op") || "";
    }
    if (limit === 3000000 || limit === 300000) {
      return i18n?.t("plan_name.mini") || "";
    }
    if (limit === 6000000 || limit === 100000) {
      return i18n?.t("plan_name.between") || "";
    }
    if (limit === 16000000 || limit === 9999999900) {
      return i18n?.t("plan_name.big") || "";
    }
  }
  if (species === SpeciesEnum.cat) {
    if (limit === 0) {
      return i18n?.t("plan_name.op") || "";
    }
    if (limit === 3000000 || limit === 300000) {
      return i18n?.t("plan_name.mini") || "";
    }
    if (limit === 6000000 || limit === 100000) {
      return i18n?.t("plan_name.between") || "";
    }
    if (limit === 16000000 || limit === 9999999900) {
      return i18n?.t("plan_name.big") || "";
    }
  }
  return i18n?.t("plan_name.liability") || "";
};

export const isUnlimitedLimit = (limit: number) => limit === 9999999900;

export const breedToSpecies = (
  breed: NullableString
): SpeciesEnum | undefined => {
  if (breed?.includes("DogBreed")) {
    return SpeciesEnum.dog;
  }
  if (breed?.includes("CatBreed")) {
    return SpeciesEnum.cat;
  }
  return undefined;
};

export const speciesToEmoji = (
  species: SpeciesEnum | undefined | null
): string => {
  if (species === SpeciesEnum.cat) {
    return "🐱";
  }
  if (species === SpeciesEnum.dog) {
    return "🐶";
  }
  return "";
};

export const speciesName = (
  species: SpeciesEnum | undefined | null
): string => {
  if (species === SpeciesEnum.cat) {
    return i18n?.t("global.cat") || "";
  }
  if (species === SpeciesEnum.dog) {
    return i18n?.t("global.dog") || "";
  }
  return "";
};

export const himHer = (sex: string): string => {
  if (sex === "male") {
    return i18n?.t("sex.male") || "";
  }
  if (sex === "female") {
    return i18n?.t("sex.female") || "";
  }
  return "";
};

export const animalPronoun = (
  sex: "male" | "female",
  species: SpeciesEnum | undefined | null
): string => {
  if (species === SpeciesEnum.dog) {
    if (sex === "female") {
      return i18n?.t("sex.dog_pronoun_female") || "";
    } else {
      return i18n?.t("sex.dog_pronoun_male") || "";
    }
  } else if (species === SpeciesEnum.cat) {
    if (sex === "female") {
      return i18n?.t("sex.cat_pronoun_female") || "";
    } else {
      return i18n?.t("sex.cat_pronoun_male") || "";
    }
  }
  return "";
};

export const amountToPay = ({
  total = 0,
  netPremium = 0,
  fees = [],
  billingInterval,
}: {
  total?: number;
  netPremium?: number;
  fees?: { maxInvoicesSpread?: number | null }[] | null;
  billingInterval?: number | null;
}) => {
  //Don't show with discount if we have first x months free
  return !!fees?.[0]?.maxInvoicesSpread
    ? (netPremium / 100 / 12) * (billingInterval || 1)
    : (total / 100 / 12) * (billingInterval || 1);
};

const ageInDays = (date: string): number => {
  return differenceInDays(new Date(), new Date(date));
};

export const getEarliestAllowedStartDate = () => {
  return addDays(new Date(), getStartOffsetInDays());
};

/**
 * This is one day to small on leap years. If we want to fix it, the backend
 * needs to be fixed first.
 */
export const getLastAllowedStartDate = () => addDays(new Date(), 364);

export const validStartDate = ({
  birthday,
  startDate,
}: {
  birthday: string;
  startDate?: string | null;
}): string => {
  const possibleStartDate =
    startDate && isValid(parseISO(startDate))
      ? new Date(startDate)
      : getEarliestAllowedStartDate();

  return format(
    clamp(possibleStartDate, {
      start: getEarliestStartDate(birthday),
      end: getLastAllowedStartDate(),
    }),
    "yyyy-MM-dd"
  );
};

export const amountStatement = (
  amount: number,
  billingInterval: number,
  currencyCode?: string
): string =>
  `${formatCurrency(amount, currencyCode)} ${amountSuffix(billingInterval)}`;

export const amountSuffix = (billingInterval: number) =>
  `/ ${
    billingInterval === 12
      ? i18n?.t("global.date.year")
      : i18n?.t("global.date.month")
  }`;

export const resparkBreedsString = (
  breedId?: string,
  mixedBreedId1?: string,
  mixedBreedId2?: string
): string =>
  breedId === "DogBreed-blandras"
    ? [mixedBreedId1, mixedBreedId2].join(",")
    : breedId ?? "";

export const resparkSexString = (sex: string, sexSituation: string): string =>
  `${sex}-${sexSituation}`;

export const getEarliestStartDate = (birthday: string): Date => {
  const MIN_AGE_IN_DAYS = getMinimumAgeInDays();

  return ageInDays(birthday) >= MIN_AGE_IN_DAYS
    ? getEarliestAllowedStartDate()
    : addDays(new Date(), MIN_AGE_IN_DAYS - ageInDays(birthday));
};

export const userAbove18 = (userBirthdate: string) => {
  return (
    dateValid(userBirthdate) &&
    differenceInYears(new Date(), new Date(userBirthdate)) >= 18
  );
};

export const getDiscountDisplayValue = ({
  fee,
  billingInterval,
}: {
  fee: {
    maxInvoicesSpread?: number | null;
    total?: null | number;
    discountName?: null | string;
  };
  billingInterval: number;
}) => {
  if (!fee.total) return undefined;

  return (
    ((fee.total /
      (billingInterval === YEARLY_BILLING_INTERVAL
        ? 12
        : fee.maxInvoicesSpread ?? 12)) *
      billingInterval) /
    100
  );
};

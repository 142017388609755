import { isFuture } from "date-fns";
import {
  isLocalStorageAvailable,
  isSessionStorageAvailable,
  onServer,
  uuid,
} from "../../utils/utils";
import { SwedishSignUpFlowState } from "../SignupFlowSweden/types";
import { GermanSignUpFlowState } from "../SignupFlowGermany/types";
import { FrenchSignUpFlowState } from "components/SignupFlowFrance/types";
import {
  getEarliestAllowedStartDate,
  validStartDate,
} from "../../utils/insurance";
import { parseRouterQueryToObject } from "../../utils/objectToQueryString";

/**
 * Used in the case of resuming preview of quote from CTA on landing page
 */
export const resumePreviewStore = {
  save: (signUpState: any): void => {
    if (
      isLocalStorageAvailable() &&
      JSON.stringify(signUpState) !==
        window.localStorage.getItem("resumePreviewState")
    )
      window.localStorage.setItem(
        "resumePreviewState",
        JSON.stringify({ ...signUpState })
      );
  },

  load: (): any => {
    const state = isLocalStorageAvailable()
      ? JSON.parse(window.localStorage.getItem("resumePreviewState") as string)
      : undefined;

    if (state) {
      state.inProgress = false;
    }

    return state;
  },

  remove: (): void => {
    if (isLocalStorageAvailable()) {
      window.localStorage.removeItem("resumePreviewState");
    }
  },
};

export const stateStore = {
  save: (signUpState: any): void => {
    if (!isSessionStorageAvailable()) {
      return;
    }

    if (
      JSON.stringify(signUpState) !==
      window.sessionStorage.getItem("signUpState")
    )
      window.sessionStorage.setItem(
        "signUpState",
        JSON.stringify({ ...signUpState })
      );
  },

  load: (): any => {
    const state = isSessionStorageAvailable()
      ? JSON.parse(window.sessionStorage.getItem("signUpState") as string)
      : undefined;

    if (state) {
      state.inProgress = false;
    }

    return state;
  },

  remove: (): void => {
    if (isSessionStorageAvailable()) {
      window.sessionStorage.removeItem("signUpState");
    }
  },
};

export function applyState<State>(
  props: any,
  initialState:
    | SwedishSignUpFlowState
    | GermanSignUpFlowState
    | FrenchSignUpFlowState,
  steps: any,
  breeds: {
    id: string;
    name: string;
    lifeAgeGroup: number;
  }[]
): State {
  const parsedRouterQuery = parseRouterQueryToObject(props, [
    "address.zip",
    "userMunicipality",
    "userPhone",
    "userRegion",
    "name",
  ]);

  let state = Object.entries(initialState).reduce((acc, [key, value]) => {
    let updatedValue;

    // If there is a query param, use that value
    if (parsedRouterQuery[key] === "") {
      updatedValue = value;
    } else {
      updatedValue = parsedRouterQuery[key] ?? value;
    }

    return {
      ...acc,
      // eslint-disable-next-line no-nested-ternary
      [key]: updatedValue,
    };
  }, parsedRouterQuery) as State;

  // Never hydrate a historic start data, choose today instead
  if (props.startDate) {
    if (props.birthdate) {
      // @ts-ignore
      state.startDate = validStartDate({
        birthday: props.birthdate,
        startDate: props.startDate,
      });
    } else if (!isFuture(new Date(props.startDate))) {
      // @ts-ignore
      state.startDate = getEarliestAllowedStartDate()
        .toISOString()
        .split("T")[0];
    }
  }

  // Transform breed id to breed object
  if (props.breed) {
    if (props.breed.includes(",")) {
      const [firstBreed, secondBreed] = props.breed.split(",");

      state = {
        ...state,
        breed: breeds?.find((b) => b.id === "DogBreed-blandras") ?? null,
        mixedBreedFHalf: breeds?.find((b) => b.id === firstBreed) ?? null,
        mixedBreedSHalf: breeds?.find((b) => b.id === secondBreed) ?? null,
      };
    } else {
      state = {
        ...state,
        breed: breeds?.find((b) => b.id === props.breed) ?? null,
      };
    }
  }

  // For nextStep, use passed nextStep or the next valid step
  return {
    ...state,
    // @ts-ignore
    showLifeInsurance: !!state.lifeLimit,
    nextStep:
      props.nextStep ||
      steps.filter((step: any) => !step.isValid(state))[0].name,
  };
}

export const deviceId = (): string => {
  if (onServer) {
    return "";
  }

  if (isLocalStorageAvailable() && localStorage.getItem("deviceId")) {
    return localStorage.getItem("deviceId") as string;
  }
  const deviceId = uuid();

  if (isLocalStorageAvailable()) {
    localStorage.setItem("deviceId", deviceId);
  }

  return deviceId;
};

import Script from "next/script";
import { useState } from "react";
import { Button } from "ui-kit/Button";

export const CalendlyPopUp = ({
  meetingBookingUrl,
  buttonStyles,
  buttonTitle,
}: {
  meetingBookingUrl: string;
  buttonStyles?: string;
  buttonTitle: string;
}) => {
  const [isScriptLoading, setIsScriptLoading] = useState(true);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .calendly-overlay {
            z-index: 2147483011 !important;
          }
          
          .calendly-popup-close {
            filter: invert(1) !important;
            top: 15px !important;
          }

          @media (min-width: 768px) {
            .calendly-popup-close {
              background-color: black !important;
              background-size: 19px !important;
              background-position: center !important;
              border-radius: 50%;
              height: 50px !important;
              width: 50px !important; 
              top: 15px !important;
            }
          }
          
          @media (max-width: 767px) {
            .calendly-overlay {
              background: white !important;
              z-index:9999999999;
            }
          }

          @media (max-width: 649px) {
            .calendly-close-overlay {
              background: white!important;
            }
          }
          `,
        }}
      />
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />
      <style></style>
      <Script
        type="text/javascript"
        src="https://assets.calendly.com/assets/external/widget.js"
        onReady={() => {
          setIsScriptLoading(false);
        }}
        onLoad={() => {
          setIsScriptLoading(false);
        }}
      />
      <Button
        variant="custom"
        color="blue100"
        textColor="blue300"
        title={buttonTitle}
        icon="calendar"
        iconFirst
        loading={isScriptLoading}
        onPress={() => {
          if (window.Calendly) {
            window.Calendly.initPopupWidget({
              url: meetingBookingUrl,
            });
          }
        }}
        className={buttonStyles}
      />
    </>
  );
};
